import { validatePasswordReset } from '@/services/settings.service';
import { ValidatePasswordResetType } from '@/types';
import { useMutation } from '@tanstack/react-query';

const useValidatePasswordResetMutation = () => {
  return useMutation({
    mutationKey: ['ValidatePasswordResetOTP'],
    mutationFn: (payload: ValidatePasswordResetType) => validatePasswordReset(payload),
  });
};

export default useValidatePasswordResetMutation;
