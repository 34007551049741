import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { COMMUNITIES_ENDPOINTS, EVENT_ENDPOINTS } from '@/constants/apiPath';
import {
  CreateCommunityPayloadType,
  GroupedCommunityType,
  PagedParams,
  UserCommunityParamsType,
} from '@/types';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { AxiosError } from 'axios';
import { getSession } from 'next-auth/react';

export async function getUserJoined(params: UserCommunityParamsType) {
  try {
    const session = await getSession();
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getUserJoined, {
      params: {
        ...params,
        // @ts-ignore
        UserId: params.UserId || session?.user?.userId || '',
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

export async function getCommunityById(params: { id: string }) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getCommunityById, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getExploreCommunities(
  params: UserCommunityParamsType
): Promise<GroupedCommunityType | undefined> {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getExplore, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data as GroupedCommunityType;
  } catch (error) {
    handleError(error as AxiosError<ErrorPayload>);
    return undefined;
  }
}

export async function getUserCreated(params: UserCommunityParamsType) {
  const session = await getSession();

  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getAll, {
      params: {
        ...params,
        CreatorId: session?.user?.userId || '',
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

export async function getCommnunitiedByCreatorId(params: UserCommunityParamsType) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getAll, {
      params: {
        ...params,
        // @ts-ignore
        CreatorId: params.CreatorId,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

export async function getCommunitySidePanelDetails(params: UserCommunityParamsType) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getSidePanelData, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getCommunityBySlug(params: { slug: string | string[] }) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getCommunityBySlug, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getCommunityEventsById(params: { CommunityId: string | string[] }) {
  try {
    const res = await axiosClientInstance.get(EVENT_ENDPOINTS.getRevamp, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getCommunityFollowers(params: { slug: string | string[] }) {
  try {
    const res = await axiosClientInstance.get(EVENT_ENDPOINTS.getAll, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getCommunityInsight(params: { userId: string | string[] }) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getCommunityBySlug, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export const getCommunityMembers = async function (params: { id: string | string[] }) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.members, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
};
export async function getCommunityUserPosts(params: { userId: string | number }) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getCommunityPostsById, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getCommunityCategories(params: PagedParams & { Label: string }) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.categories, {
      params: {
        IncludeTopCommunities: false,
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getCommunityInterests(params: PagedParams) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.interests, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getCommunityByUserInterests(params: {
  Search: string;
  PageSize: number;
  PageNumber: number;
}) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.userInterests, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function getUserInterests(params: { Search: string }) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.interests, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}
export async function createCommunity(payload: CreateCommunityPayloadType) {
  try {
    const res = await axiosClientInstance.post(COMMUNITIES_ENDPOINTS.create, payload, {
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>, 'Unable to create community');
  }
}
